<template>
    <div class="legislat xi">
        <nh-com></nh-com>
        <div class="banner">
        </div>
        <div class="content">
            <div class="ny-content flex-center">
                <img v-if="type == 4" class="img-box" src="../assets/img/img4.png" />
                <img v-if="type == 2" class="img-box" src="../assets/img/img5.jpg" />
                <img v-if="type == 3" class="img-box" src="../assets/img/img3.jpg" />
            </div>
        </div>
        <ft-com @getType="getType"></ft-com>
    </div>
</template>

<script>
import ftCom from '../components/footer';
import nhCom from "../components/navheader";
export default {
    name: "Legislation",
    components: {
        ftCom,
        nhCom,
    },
    data() {
        return {
            type:this.$route.query.type,
        }
    },
    methods: {
        getType(data) {
            this.type = data
            console.log(this.type)
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
@import "../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../assets/img/news_banner.jpg")center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-search {
    position: absolute;
    top: calc(50% - 30px);
    right: 0;
    padding: 0 30px;
    width: 850px;
    height: 60px;
    border-radius: 60px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
}

.banner-search input {
    float: left;
    height: 60px;
    line-height: 60px;
    background: none;
    width: 700px;
    font-size: 16px;
}

.banner-search .search-btn {
    float: right;
    font-size: 28px;
    color: #1fb7a6;
    background: none;
    line-height: 60px;
}

/**/
.content {
    background: #f7f7f7;
}

.ny-content {
    width: 1200px;
    margin: 10px auto;
    padding: 0px 20px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.ny-content img {
    width: 100%;
    height: 100%;
}

.ny-content .img-box {
    width: 80%;
    min-height: 800px;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.legis-item {
    float: left;
    margin: 15px;
    cursor: pointer;
}

.legis-img {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 135px;
    height: 135px;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
}

.legis-item p {
    margin: 10px 0;
    width: 135px;
    height: 60px;
    text-align: center;
    color: #333333;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.legis-item:hover .legis-img {
    border: 1px solid #1fb7a6;
}

.legis-item:hover p {
    color: #1fb7a6;
}

#page {
    text-align: center;
}
</style>
<style>
.legislat .navheader nav .navbar ul li.active>a {
    color: #333333 !important;
}

.legislat .navheader nav .navbar ul li.active>a::before {
    width: 0 !important;
}

.legislat .bar-list .yysc {
    display: none !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination {
    font-weight: 500;
}
</style>
